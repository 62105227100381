/* 
===============
PROJECTS
===============
*/
.project__title {
    font-family: $button-text-font-family;
    font-weight: normal;
	font-size: 26px;
	margin: 0 0 1em;
	padding-right: 0
}

.project__content {
    //margin: 35px auto 30px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 10px;
}

.project__description {
	margin-top: 0;
    font-size: $type-size-6;
}
//.project__labels {
//    
//}
//
///* Define the Sassy Map called $labels */
//$labels: (
//  java: (Java, #f7a116),
//  unity: (Unity, #a4f715),
//  go: (Go, #158df7)
//);
// 
///* For each key in the map, created an own class */
//@each $name, $value in $icons {
//  .icon--#{$name} {
//    content: $value;
//  }
//}

.project__meta {
	color: #909090;
	font-family: $global-font-family;
	font-size: 15px;
}

.project__meta span {
	display: inline-block;
	margin-bottom: 2px
}

.project__meta span a {
    text-decoration: none;
    color: $info-color;
}

.modal-center-wrapper {
    text-align: center;
}

.modal-center {
	display: inline-block;
    margin: 0 0 2em;
}

.show-modal {
	overflow: hidden
}

.modal {
	background: rgba(0, 0, 0, .15);
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	overflow-y: scroll;
	-webkit-transition: opacity 300ms ease-in-out 1ms, visibility 1ms linear 302ms;
	transition: opacity 300ms ease-in-out 1ms, visibility 1ms linear 302ms;
	width: 100%;
	z-index: 20
}

.modal-content {
	background: transparent;
	cursor: default;
	float: right;
	max-width: 850px;
	min-height: 100%;
	opacity: 0;
	padding: 45px 4.2105%;
	-webkit-transition: opacity 400ms ease-in-out;
	transition: opacity 400ms ease-in-out;
	width: 100%;
    height: 100%;
    max-height: 100%;
}

.modal-content--show {
	background: #FFF;
	opacity: 1
}

.button {
	background: transparent;
	border: 0;
	cursor: pointer
}

.button--close {
    opacity: 1;
	position: absolute;
	right: 4.2105%;
	top: 48px
}

.loading {
	margin: auto;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1
}

.loading--show {
	width: 40px;
	height: 40px;
	background-color: #111;
	border-radius: 100%;
	-webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
	animation: sk-pulseScaleOut 1s infinite ease-in-out;
	z-index: 25
}

@-webkit-keyframes sk-pulseScaleOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0
	}
}

@keyframes sk-pulseScaleOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0
	}
}

/*
================================
PROJECT FEED Section
================================
*/


.project__header .title {
	color: #111;
	font-size: 26px;
	line-height: 1.15
}

.brick {
	float: left;
	max-width: 600px;
	margin: 0;
	opacity: 0;
	position: relative;
	-webkit-transition: opacity 300ms ease-in-out;
	transition: opacity 300ms ease-in-out;
	width: 100%;
    max-height: 600px;
}

.brick--show {
	opacity: 1
}

.brick>a {
	display: block
}

.brick::after {
	clear: both;
	content: '';
	display: block
}

.projects-feed .project {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover
}

.projects-feed .project,
.projects-feed .project .thumb {
	overflow: hidden
}

.projects-feed .thumb {
	position: relative
}

.projects-feed .thumb {
	-ms-transition: opacity 350ms ease-in-out, -ms-transform 400ms ease-in-out;
	-webkit-transition: opacity 350ms ease-in-out, -webkit-transform 400ms ease-in-out;
	transition: opacity 350ms ease-in-out, transform 400ms ease-in-out;
	z-index: 2
}

.projects-feed a:hover .thumb {
	opacity: 0;
	-ms-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	transform: scale(1.2)
}

.thumbnail-title-wrap {
	background-image: radial-gradient(circle, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .65) 100%);
	height: 100%;
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	text-align: center;
	-ms-transform: scale(1.3);
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
	-ms-transition: opacity 400ms ease-in-out, -ms-transform 400ms ease-in-out;
	-webkit-transition: opacity 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	transition: opacity 400ms ease-in-out, transform 400ms ease-in-out;
	width: 100%
}

.projects-feed .project a:hover .thumbnail-title-wrap {
	opacity: 1;
	-ms-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1)
}

.thumbnail-title-wrap::before {
	content: '';
	display: inline-block;
	height: 100%;
	margin-right: -.25em;
	vertical-align: middle
}

.center {
	display: inline-block;
	margin: 0;
	max-width: 90%;
}

.title--thumbnail {
	color: #FFF;
	display: inline-block;
    font-family: $button-text-font-family;
    font-weight: normal;
	font-size: 30px;
	margin-bottom: 10px;
	padding-right: 0
}

.thumb img {
    object-fit: cover;
	height: 100%;
	width: 100%;
}

div.image,
div.audio,
div.video {
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 750px;
	text-align: center
}

@-webkit-keyframes sk-pulseScaleOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0
	}
}

@keyframes sk-pulseScaleOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0)
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0
	}
}

@include breakpoint($tiny) {
    
	.brick,
    .thumbnail-title-wrap,
    .thumb img {
	   height: 450px;
       width: 450px;
    }
    
    .projects-feed {
        width: 450px;
    }
    
    .title--thumbnail {
      font-size: 25px;
    }
    
	.page {
		margin-bottom: 80px
	}
	.project__description {
		float: left;
		padding-right: 3.3333%;
		width: 69.3333%
	}
	.project__meta {
		float: right;
		padding-left: 3.3333%;
		width: 30.6667%
	}
	.project__description--no-meta {
		float: none;
		padding-right: 0;
		width: 100%
	}
	div.image,
	div.audio,
	div.video {
		margin-top: 40px
	}
	.asset-description {
		padding: 60px 0 20px
	}
}

@include breakpoint($small) {
    
	.brick,
    .thumbnail-title-wrap,
    .thumb img {
	   height: 300px;
        width: 300px;
    }
    
    .projects-feed {
        width: 600px;
    }
    
    .title--thumbnail {
      font-size: 25px;
    }
    
	.page {
		margin-bottom: 80px
	}
	.project__description {
		float: left;
		padding-right: 3.3333%;
		width: 69.3333%
	}
	.project__meta {
		float: right;
		padding-left: 3.3333%;
		width: 30.6667%
	}
	.project__description--no-meta {
		float: none;
		padding-right: 0;
		width: 100%
	}
	div.image,
	div.audio,
	div.video {
		margin-top: 40px
	}
	.asset-description {
		padding: 60px 0 20px
	}
}

@include breakpoint($medium) {
    
	.brick,
    .thumbnail-title-wrap,
    .thumb img {
	   height: 350px;
       width: 350px;
    }
    
    .projects-feed {
        width: 700px;
    }
    
    .title--thumbnail {
      font-size: 28px;
    }
    
	.page {
		margin-bottom: 80px
	}
	.project__description {
		float: left;
		padding-right: 3.3333%;
		width: 69.3333%
	}
	.project__meta {
		float: right;
		padding-left: 3.3333%;
		width: 30.6667%
	}
	.project__description--no-meta {
		float: none;
		padding-right: 0;
		width: 100%
	}
	div.image,
	div.audio,
	div.video {
		margin-top: 40px
	}
	.asset-description {
		padding: 60px 0 20px
	}
}

@include breakpoint($medium-wide) {
    .brick,
    .thumbnail-title-wrap,
    .thumb img {
	   height: 300px;
       width: 300px;
    }
    
    .projects-feed {
        width: 900px;
    }
    
    .title--thumbnail {
      font-size: 25px;
    }
}

@include breakpoint($large) {
	.brick,
    .thumbnail-title-wrap,
    .thumb img {
	   height: 340px;
        width: 340px;
    }
    
    .projects-feed {
        width: 1020px;
    }
}

@include breakpoint($x-large) {
	.brick,
    .thumbnail-title-wrap,
    .thumb img {
	   height: 400px;
        width: 400px;
    }
    
    .projects-feed {
        width: 1200px;
    }
}