/* ==========================================================================
   ARCHIVE
   ========================================================================== */

.archive {
  margin-top: 1em;
  margin-bottom: 2em;

  @include breakpoint($large) {
    float: right;
    width: calc(100% - #{$right-sidebar-width-narrow});
    padding-right: $right-sidebar-width-narrow;
  }

  @include breakpoint($x-large) {
    width: calc(100% - #{$right-sidebar-width});
    padding-right: $right-sidebar-width;
  }
}

.archive__item {
  position: relative;

//  a::before {
//    content: 'world';
//    position: absolute;
//    left: 0;
//    top: 0;
//    right: 0;
//    bottom: 0;
//  }
}

.archive__subtitle {
  margin: 1.414em 0 0;
  padding-bottom: 0.5em;
  font-size: $type-size-5;
  color: $muted-text-color;
  //border-bottom: 1px solid $border-color;

  + .list__item .archive__item-title {
    margin-top: 0.5em;
  }
}

.archive__item-title {
  margin-bottom: 0.25em;
  font-family: $page-title-font-family;
  line-height: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  a + a {
    opacity: 0.5;
  }
}

/* remove border*/
.page__content {
  .archive__item-title {
    margin-top: 1em;
    border-bottom: none;
  }
}

.archive__item-excerpt {
  margin-top: 0;
  font-size: $type-size-6;

  & + p {
    text-indent: 0;
  }
}

.archive__item-teaser:hover .archive__item-teaser-hover-overlay {
  opacity: 1;
  // Make a semi-transparent background so text is still visible
  background-color: rgba(0,0,0,0.5);
}

.archive__item-teaser {
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;

  img {
    width: 100%;
  }

  // Need to use ampersand in order for nested method to work
    // Commented out as this essentially is the same as above
    //  &:hover {
    //    .archive__item-teaser-hover-overlay {
    //      opacity: 1;
    //      background-color: rgba(0,0,0,0.5);
    //    }
    //  }
    
  .archive__item-teaser-hover-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .2s ease;
  }
    
  .archive__item-teaser-hover-text {
    color: white;
    font-size: $type-size-4;
    font-family: $button-text-font-family;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    //padding: 0px 0px 0px 0px;
      
    @include breakpoint($tiny) {
      font-size: $type-size-3;
    }
      
    @include breakpoint($small) {
      font-size: $type-size-4;
    }
  }
    
  .archive__item-teaser-hover-link {
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    display: block;
    text-align: center;
    text-decoration: none;
  }
}

.archive__item-caption {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  padding: 2px 5px;
  color: #fff;
  font-family: $caption-font-family;
  font-size: $type-size-8;
  background: #000;
  text-align: right;
  z-index: 5;
  opacity: 0.5;
  border-radius: $border-radius 0 0 0;

  @include breakpoint($large) {
    padding: 5px 10px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}

/*
   List view
   ========================================================================== */

// For items in the blog list and search list
.list__item {
  .page__meta__block {
    display: inline-block;
  }
    
  .page__meta__date {
    font-size: 0.7em; 
    margin: 0 8px 0 2px;
    color: $primary-color;
  }
    
  .page__meta {
    margin: 0 0 4px;
    font-size: 0.6em;
  }
    
  .archive__item-excerpt {
    margin-top: 5px;
  }
    
  position: relative;
  float: left;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  clear: both;
  font-size: 1.125em;

  h2 {
    margin: 0.5em 0; 
  }
    
  a {
    color: inherit;
    text-decoration: none;
  }

  .archive__item-teaser {
    margin-bottom: 0.5em;

    img {
      margin: 0;
      vertical-align: top;
    }
  }

  @include breakpoint($tiny) {
    h2 {
      font-size: 1rem;
    }    

    .archive__item-teaser {
      float: left;
      width: span(5 of 12);
      height: 200px;
    }

    .archive__item-body {
      float: right;
      width: span(7 of 12);
    }
  }

  @include breakpoint($small) {
    h2 {
      font-size: 1rem;
    }  
    .archive__item-teaser {
      float: left;
      width: span(4 of 12);
      height: 200px;
    }

    .archive__item-body {
      float: right;
      width: span(8 of 12);
    }
  }

  @include breakpoint($medium) {
    h2 {
      font-size: 1.5rem;
    }
    .archive__item-teaser {
      float: left;
      width: span(4 of 12);
      height: 200px;
    }
      
    .archive__item-body {
      float: right;
      padding-left: 10px;
      width: span(8 of 12);
    }
  }
}

.list__wrapper {
  @include clearfix();
}

/*
   Grid view
   ========================================================================== */

.archive {
  .grid__wrapper {
    /* extend grid elements to the right */
      border-right: 1px solid $border-color;
      width: 100%;

    @include breakpoint($large) {
      //margin-right: -1 * $right-sidebar-width-narrow;
    }

    @include breakpoint($x-large) {
      //margin-right: -1 * $right-sidebar-width;
    }
  }
}

.grid__item {
  margin-bottom: 2em;
    
  a {
    color: inherit;
    text-decoration: none;
  }

  @include breakpoint($small) {
    float: left;
    width: span(4 of 12);

    // Fix the weird overflow when window is smaller than medium
    &:nth-child(3n + 1) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(3n + 2) {
      clear: none;
      margin-left: gutter(of 12);
    }

    &:nth-child(3n + 3) {
      clear: none;
      margin-left: gutter(of 12);
    }
    
  }

  @include breakpoint($medium) {
    margin-left: 0; /* override margin*/
    margin-right: 0; /* override margin*/
    width: span(4 of 12);
      
    &:nth-child(3n + 1) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(3n + 2) {
      clear: none;
      margin-left: gutter(of 12);
    }

    &:nth-child(3n + 3) {
      clear: none;
      margin-left: gutter(of 12);
    }
  }
    
  .page__meta__block {
    display: inline-block;
  }
    
  .page__meta__date {
    font-size: 0.7em; 
    margin: 0 8px 0 2px;
    color: $primary-color;
  }

  .page__meta {
    margin: 0 0 4px;
    font-size: 0.6em;
  }

  .archive__item-title {
    margin-top: 0.5em;
    font-size: $type-size-5;
  }

  .archive__item-excerpt {
    display: none;

    @include breakpoint($medium) {
      display: block;
      font-size: $type-size-6;
    }
  }

  .archive__item-teaser {
    @include breakpoint($small) {
      max-height: 200px;
    }

    @include breakpoint($medium) {
      max-height: 200px;
    }
  }
}

/*
   Related view
   ========================================================================== */

.related__item {
  margin-bottom: 2em;
    
  a {
    color: inherit;
    text-decoration: none;
  }

  @include breakpoint($small) {
    float: left;
    margin-left: 0; /* override margin so they fill up the page*/
    margin-right: 0; /* override margin so they fill up the page*/
    width: span(5 of 10);

    &:nth-child(2n + 1) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(2n + 2) {
      clear: none;
      margin-left: gutter(1 of 10);
    }
  }

  @include breakpoint($medium) {
    margin-left: 0; /* override margin so they fill up the page*/
    margin-right: 0; /* override margin so they fill up the page*/
    width: span(4 of 12);

    &:nth-child(2n + 1) {
      clear: none;
    }

    &:nth-child(4n + 1) {
      clear: both;
    }

    &:nth-child(4n + 2) {
      clear: none;
      margin-left: gutter(1 of 12);
    }

    &:nth-child(4n + 3) {
      clear: none;
      margin-left: gutter(1 of 12);
    }

    &:nth-child(4n + 4) {
      clear: none;
      margin-left: gutter(1 of 12);
    }
  }
    
  .page__meta__block {
    display: inline-block;
  }
    
  .page__meta__date {
    font-size: 0.7em; 
    margin: 0 8px 0 2px;
    color: $primary-color;
  }

  .page__meta {
    margin: 0 0 4px;
    font-size: 0.6em;
  }

  .archive__item-title {
    margin-top: 0.5em;
    font-size: $type-size-5;
  }

  .archive__item-excerpt {
    display: none;

    @include breakpoint($medium) {
      display: block;
      font-size: $type-size-6;
    }
  }

  .archive__item-teaser {
    @include breakpoint($small) {
      max-height: 200px;
      img {
        height: 200px;
      }
    }

    @include breakpoint($medium) {
      max-height: 200px;
      img {
        height: 150px;
      }
    }
      
    @include breakpoint($large) {
      img {
        height: 150px;
      }
    }

    @include breakpoint($x-large) {
      img {
        height: 150px;
      }
    }
  }
}


/*
   Features
   ========================================================================== */

.feature__wrapper {
  @include clearfix();
  margin-bottom: 2em;

  // This adjusts the spacing for the 
  // Feature Row. Note that markdownify
  // adds white spaces in between elements
  .archive__item-title {
    margin-top: 0.5em;
    margin-bottom: 0.25em;
  }
}

.feature__item {
  position: relative;
  margin-bottom: 2em;
  font-size: 1.125em;

  @include breakpoint($small) {
    float: left;
    margin-bottom: 0;
    width: span(4 of 12);

    &:nth-child(3n + 1) {
      clear: both;
      margin-left: 0;
    }

    &:nth-child(3n + 2) {
      clear: none;
      margin-left: gutter(of 12);
    }

    &:nth-child(3n + 3) {
      clear: none;
      margin-left: gutter(of 12);
    }
  }

  .archive__item-teaser {
    @include breakpoint($small) {
      height: 150px; // TODO: figure out a better way
      overflow: hidden;
    }
  
    @include breakpoint($medium) {
      height: 250px; // TODO: figure out a better way
      overflow: hidden;
    }

      
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  .archive__item-body {
    padding-left: gutter(1 of 12);
    padding-right: gutter(1 of 12);
  }

  &--left {
    position: relative;
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    clear: both;
    font-size: 1.125em;

    .archive__item-teaser {
      margin-bottom: 2em;
    }

    a.btn::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    @include breakpoint($small) {
      .archive__item-teaser {
        float: left;
        width: span(5 of 12);
      }

      .archive__item-body {
        float: right;
        padding-left: gutter(0.5 of 12);
        padding-right: gutter(1 of 12);
        width: span(7 of 12);
      }
    }
  }

  &--right {
    position: relative;
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    clear: both;
    font-size: 1.125em;

    .archive__item-teaser {
      margin-bottom: 2em;
    }

    a.btn::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    @include breakpoint($small) {
      text-align: right;

      .archive__item-teaser {
        float: right;
        width: span(5 of 12);
      }

      .archive__item-body {
        float: left;
        width: span(7 of 12);
        padding-left: gutter(0.5 of 12);
        padding-right: gutter(1 of 12);
      }
    }
  }

  &--center {
    position: relative;
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    clear: both;
    font-size: 1.125em;

    .archive__item-teaser {
      margin-bottom: 2em;
    }

    a.btn::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    @include breakpoint($small) {
      text-align: center;

      .archive__item-teaser {
        margin: 0 auto;
        width: span(5 of 12);
      }

      .archive__item-body {
        margin: 0 auto;
        width: span(7 of 12);
      }
    }
  }
    
  &--single {
    position: relative;
    float: left;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    clear: both;
    font-size: 1.125em;

    .archive__item-teaser {
      margin-bottom: 2em;
    }

    @include breakpoint($small) {
      .archive__item-teaser {
        float: left;
        width: span(6 of 12);
      }

      .archive__item-body {
        float: right;
        padding-left: gutter(0.5 of 12);
        padding-right: gutter(1 of 12);
        width: span(6 of 12);
      }
    }
  }
}

/* Place inside an archive layout */

.archive {
  .feature__wrapper {
    .archive__item-title {
      margin-top: 0.25em;
      font-size: 1em;
    }
  }

  .feature__item,
  .feature__item--left,
  .feature__item--center,
  .feature__item--right {
    font-size: 1em;
  }
}

/*
   Wide Pages
   ========================================================================== */

  .wide {
  .archive {
    @include breakpoint($large) {
      padding-right: 0;
    }

    @include breakpoint($x-large) {
      padding-right: 0;
    }
  }
}