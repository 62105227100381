/* ==========================================================================
   Variables
   ========================================================================== */

/*
   Typography
   ========================================================================== */

$doc-font-size: 16 !default;

/* paragraph indention */
$paragraph-indent: false !default; // true, false (default)
$indent-var: 1.3em !default;

/* system typefaces */
$serif: Georgia, Times, serif !default;
$sans-serif: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
  "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;
$monospace: Monaco, Consolas, "Lucida Console", monospace !default;

/* sans serif typefaces */
$sans-serif-narrow: $sans-serif !default;
$helvetica: Helvetica, "Helvetica Neue", Arial, sans-serif !default;

/* serif typefaces */
$georgia: Georgia, serif !default;
$times: Times, serif !default;
$bodoni: "Bodoni MT", serif !default;
$calisto: "Calisto MT", serif !default;
$garamond: Garamond, serif !default;

/* Google Custom Fonts */
$dancing: 'Dancing Script', cursive;
$raleway: 'Raleway', sans-serif;
$source-serif: 'Source Serif Pro', serif;
$roboto: 'Roboto', sans-serif;

$site-title-font-family: $dancing;
$site-title-slogan-font-family: $raleway;
$page-title-font-family: $raleway;
$page-lead-font-family: $raleway;
$global-font-family: $source-serif;
$header-font-family: $helvetica !default;
$caption-font-family: $sans-serif-narrow !default;
$footer-font-family: $sans-serif-narrow;
$button-text-font-family: $raleway;

/* type scale */
$type-size-1: 2.441em !default; // ~39.056px
$type-size-2: 1.953em !default; // ~31.248px
$type-size-3: 1.563em !default; // ~25.008px
$type-size-4: 1.25em !default; // ~20px
$type-size-5: 1em !default; // ~16px
$type-size-6: 0.75em !default; // ~12px
$type-size-7: 0.6875em !default; // ~11px
$type-size-8: 0.625em !default; // ~10px

/*
   Colors
   ========================================================================== */

$gray: #7a8288 !default;
$dark-gray: mix(#000, $gray, 40%) !default;
$darker-gray: mix(#000, $gray, 60%) !default;
$light-gray: mix(#fff, $gray, 50%) !default;
$lighter-gray: mix(#fff, $gray, 90%) !default;

$background-color: #fff !default;
$code-background-color: #fafafa !default;
$code-background-color-dark: $light-gray !default;
$text-color: $dark-gray !default;
$muted-text-color: mix(#fff, $text-color, 35%) !default;
$border-color: $lighter-gray !default;
$form-background-color: $lighter-gray !default;
$footer-background-color: $lighter-gray !default;

$primary-color: #7a8288 !default;
$success-color: #62c462 !default;
$warning-color: #f89406 !default;
$danger-color: #ee5f5b !default;
$info-color: #52adc8 !default;

/* YIQ color contrast */
$yiq-contrasted-dark-default: $dark-gray !default;
$yiq-contrasted-light-default: #fff !default;
$yiq-contrasted-threshold: 175 !default;
$yiq-debug: false !default;

/* brands */
$bitbucket-color: #205081 !default;
$facebook-color: #3b5998 !default;
$github-color: #171516 !default;
$gitlab-color: #e24329 !default;
$instagram-color: #517fa4 !default;
$linkedin-color: #007bb6 !default;
$rss-color: #fa9b39 !default;
$twitter-color: #55acee !default;
$youtube-color: #bb0000 !default;

/* links */
$link-color: $info-color !default;
$link-color-hover: mix(#000, $link-color, 25%) !default;
$link-color-visited: mix(#fff, $link-color, 25%) !default;
$masthead-link-color: $primary-color !default;
$masthead-link-color-hover: mix(#000, $primary-color, 25%) !default;
$navicon-link-color-hover: mix(#fff, $primary-color, 75%) !default;

/* syntax highlighting (base16) */
$base00: #263238 !default;
$base01: #2e3c43 !default;
$base02: #314549 !default;
$base03: #546e7a !default;
$base04: #b2ccd6 !default;
$base05: #eeffff !default;
$base06: #eeffff !default;
$base07: #ffffff !default;
$base08: #f07178 !default;
$base09: #f78c6c !default;
$base0a: #ffcb6b !default;
$base0b: #c3e88d !default;
$base0c: #89ddff !default;
$base0d: #82aaff !default;
$base0e: #c792ea !default;
$base0f: #ff5370 !default;

/*
   Breakpoints
   ========================================================================== */
$tiny: 500px !default;
$small: 600px !default;
$medium: 768px !default;
$medium-wide: 900px !default;
$large: 1024px !default;
$x-large: 1280px !default;

/*
   Grid
   ========================================================================== */

$right-sidebar-width-narrow: 100px !default;
$right-sidebar-width: 150px !default;
$right-sidebar-width-wide: 200px !default;

/*
   Other
   ========================================================================== */

$border-radius: 4px !default;
$box-shadow: 0 1px 1px rgba(0, 0, 0, 0.125) !default;
$nav-height: 2em !default;
$nav-toggle-height: 2rem !default;
$navicon-width: 1.5rem !default;
$navicon-height: 0.25rem !default;
$global-transition: all 0.2s ease-in-out !default;
$intro-transition: intro 0.3s both !default;
